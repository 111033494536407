import React, { useEffect, useState, useMemo, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../Util/TableContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from "../Util/filters";
import axios from "../../api/axios";
import { colors } from "@mui/material";

const Leave = () => {
  const [data, setData] = useState([]);
  const LeaveListURL = "/GetAllLeaveMasterFromCompanyId";
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const doFetch = async () => {
      // const response = await fetch('https://randomuser.me/api/?results=100');
      const response = await axios.get(
        LeaveListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      console.log(response.data);
      //  response.data.shift();
      setData(response.data);
    };
    doFetch();
  }, []);

  const EditData = (id) => {
    //let { id} = data;
    // localStorage.setItem('EditUserId', id);
    //alert(id);
    navigate("/LeaveMaster", { state: { id } });
  };

  return (
    <>
      <div className="layoutContainer">
        <div className="row g-3">
          <div className="col-lg-8" style={{ textAlign: "Left" }}>
                  {" "}
                  <h4>
                    <b>Leave List</b>
                  </h4>
                </div>
                <div className="col-lg-2">
                  {/* <button onClick={(e) => {EditData(0)}}  className="btn btn-success">Add New (+)</button> */}
                  <button
                    onClick={(e) => {
                      EditData(0);
                    }}
                    className="btn btn-primary"
                  >
                    Add New (+)
                  </button>
                </div>
                <div className="col-lg-2">
            <DownloadTableExcel
              filename="Leave Table"
              sheet="Leave"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-success">
                Export to Excell
              </button>
            </DownloadTableExcel>
          </div>
              </div>
              <div className="scollDiv mt-4">
          <table
            ref={tableRef}
            className="attendTable text-center"
            style={{ width: "100%" }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td>Sr No.</td>
                <td>Employee CardNo</td>
                <td>Employee Name</td>
                <td>Leave Type</td>
                <td>From Date</td>
                <td>To Date</td>
                <td>Leave Reason</td>
                <td>Action</td>
              </tr>

              {data.map((att, index) => (
                <>
                  <tr data-index={index}>
                    <td>{att.rowNum}</td>
                    <td>{att.employeeCardNo}</td>
                    <td>{att.employeeName}</td>
                    <td>{att.leaveType}</td>
                    <td>{att.fromDate}</td>
                    <td>{att.toDate}</td>
                    <td>{att.leaveResion}</td>
                    <td>{<button
                      style={{ width: "40%", height: "40px", color: "green" }}
                      onClick={(e) =>
                        EditData(att.leaveMasterId)
                      }
                      className="btn"
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </button>}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>       
    </>
  );
};

export default Leave;
