import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import SideMenu from "../../Layout/SideMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";

export const DeviceMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#7dbcea",
  };

  const INSERTDevice_URL = "/InsertDeviceDetails";
  const UPDATEDevice_URL = "/UpdateDeviceDetails";
  const DeviceDETAIL_URL = "/GetDeviceFromDeviceId";
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();

  const [DeviceId, setDeviceId] = useState(0);
  const [UnitId, setUnitId] = useState(0);

  const [UnitName, setUnitName] = useState("");
  const [DeviceIpAddress, setDeviceIpAddress] = useState("");
  const [PortNo, setPortNo] = useState("4370");
  const [DeviceType, setDeviceType] = useState("IN");
  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);

  const { state } = useLocation(0);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
      setIsActive(true);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsActive(false);
    }

    console.log(IsActive);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };

  useEffect(() => {
    setDeviceId(state.id);
    if (state.id == 0) {
      //alert('New');
      setDeviceIpAddress("");
      setIsActive(false);
      setbtnLabel("Save");
    } else {
      // alert(state.id);
      const FetchDeviceDetails = async () => {
        const response = await axios.get(
          DeviceDETAIL_URL + "?DeviceId=" + state.id
        );

        setDeviceIpAddress(response.data.deviceIpAddress);
        setPortNo(response.data.portNo);
        setDeviceType(response.data.deviceType);
        setDeviceId(response.data.deviceId);
        setUnitName(response.data.unitName);
        setUnitId(response.data.unitId);
        console.log(UnitId);
        console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActiveValue(true);
          console.log(IsActive);
        } else {
          console.log("set False");

          // setIsActive(false);
          setIsActiveValue(false);
          console.log(IsActive);
        }
      };
      FetchDeviceDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  // useEffect(() => {

  //   setErrMsg('');
  //   //UserList.apply();

  // }, [ , userName, Password ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (DeviceId == 0) {
        console.warn(localStorage.getItem("companyId"));
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          INSERTDevice_URL,
          JSON.stringify({
            DeviceIpAddress: DeviceIpAddress,
            DeviceType: DeviceType,
            PortNo: PortNo,
            UnitId: UnitMasterDDLRef.current.getUnitId(),
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setDeviceIpAddress("");
        setUnitId(0);
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/Device");
      } else {
        setUnitId(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitMasterDDLRef.current.getUnitId());
        console.log(UnitId);
        const response = await axios.post(
          UPDATEDevice_URL,
          JSON.stringify({
            DeviceId: DeviceId,
            DeviceIpAddress: DeviceIpAddress,
            DeviceType: DeviceType,
            PortNo: PortNo,
            UnitId: UnitId,
            IsActive: IsActive,
            CompanyId: localStorage.getItem("companyId"),
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setDeviceIpAddress("");
        setDeviceId(0);
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/Device");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
            <div className="layoutContainer screenCenter">
            <div className="loginContainer">
                <form onSubmit={handleSubmit}>
                  <h2>Device Master</h2>
                  <input
                    type="Text"
                    className="form-controll"
                    id="DeviceIpAddress"
                    onChange={(e) => setDeviceIpAddress(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={DeviceIpAddress}
                    placeholder="Device Name"
                    required
                  ></input>
                  <input
                    type="Text"
                    className="form-controll"
                    id="PortNo"
                    onChange={(e) => setPortNo(e.target.value)}
                    ref={userRef}
                    autoComplete="off"
                    value={PortNo}
                    placeholder="PortNo"
                    required
                  ></input>
                  {/* <input type="Text" className="form-controll" id="DeviceType" onChange={(e) => setDeviceType(e.target.value)}
                            ref={userRef} autoComplete="off" value={DeviceType} placeholder="DeviceType" required></input> */}
                  <select
                    value={DeviceType}
                    onChange={(e) => setDeviceType(e.target.value)}
                  >
                    <option value="IN">IN</option>
                    <option value="OUT">OUT</option>
                  </select>
                  <UnitMasterDDL
                    ref={UnitMasterDDLRef}
                    unitId={UnitId}
                  ></UnitMasterDDL>
                  <label className="form-controll" htmlFor="IsActive">
                    Is Active
                  </label>{" "}
                  &nbsp;
                  <input
                    type="CheckBox"
                    id="IsActive"
                    checked={IsActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></input>
                  {/* 
<input type="RetypePassword" className="form-controll" id="RetypePassword" onChange={(e) => setPassword(e.target.value)}
              autoComplete="off" value={Password} placeholder="Retype Password" required></input> */}
                  <button className="btn btn-primary"> {btnLabel}</button>
                  <div>
                    <label>{MSG}</label>
                  </div>
                </form>
              </div>
            </div>
    </>
  );
};
