import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  ContainerOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  PieChartOutlined,
  MailOutlined,
  UserSwitchOutlined,
  MenuUnfoldOutlined,
  IdcardOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  PrinterOutlined,
  EnvironmentOutlined,
  SignatureOutlined,
  CarryOutOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useState } from "react";
import SCRIPTINDIABLUELOGO from "../images/logo-black Blue.png";
import { textAlign } from "@mui/system";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Dashboard", "Dashboard", <PieChartOutlined />),

  getItem("Masters", "sub1", <BankOutlined />, [
    getItem("Department", "Department"),
    getItem("Designation", "Designation"),
    getItem("Shift", "Shift"),

    //getItem("Shift Group", "ShiftGroup"),
    //   getItem("Unit", "Unit"),
    getItem("Paid Holiday", "PaidHoliday"),
    getItem("Users", "Users"),
     getItem("Device", "Device"),
     getItem("Company", "Company"),
     getItem("Client", "Client"),
  ]),
  getItem("Employee Master", "Employee", <UsergroupAddOutlined />),
  getItem("Punch Management", "MissedPunch", <CarryOutOutlined />),
  // getItem("Leave", "Leave"),
  getItem("Attend Reports", "sub2", <PrinterOutlined />, [
    // getItem("Employee Att", "DateRangeWiseAttendance"),
    // getItem("DateWise Att", "DateWiseAttendance"),
    getItem("In Out", "InOutReport"),
    // getItem("In OutC", "InOutReportC"),
    getItem("Absent Present", "AbsentPresentReport"),
    // getItem("Device Log", "AttendanceLog"),
    getItem("Employee Punch", "InOutLogReport"),
    getItem("Geofence Report", "GeofenceReport"),
    // getItem("ShiftPlaning", "ShiftPlaning"),
     getItem("Late Coming", "LateComing"),
     getItem("Early Going", "EarlyGoing"),
    // getItem("Work Hour Report", "WorkHourReport"),

    // getItem("Submenu", "sub3", null, [
    //   getItem("Option 11", "11"),
    //   getItem("Option 12", "12"),
    // ]),
  ]),
     getItem("Salary Reports", "sub3", <FileTextOutlined  />, [
     //getItem("Employee Salary", "EmployeeSalary"),
     getItem("Salary Report", "SalaryReport"),
     getItem("PF Report", "PFReport"),
     getItem("Bonus Report", "BonusReport"),
     getItem("ESIC Report", "ESICReport"),
  //   getItem("Salary Slip","SalarySlip"),
  //   getItem("Salary Report C", "SalaryReportC"),
  //   getItem("Over Time", "3"),
  //   getItem("Loan Advance", "4"),
  //   getItem("PF", "5"),
  //   getItem("Increament", "6"),
  //   getItem("Paid Holiday", "7"),
  //   getItem("User", "7"),
  //   getItem("Device", "7"),
  //   getItem("Company", "7"),
   ]), 
  getItem("Client Visits", "sub4", <EnvironmentOutlined />, [ 
  getItem("Client Visit", "ClientVisit"),
  // getItem("GPS Detect", "GPSDetect", <MailOutlined />),
  getItem("Client Visit DateWise", "DateWiseClientVisit")]),
  getItem("Change Password", "ChangePassword", <SignatureOutlined />)
];
const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  // const toggleCollapsed = () => {
  //   if (collapsed) {
  //     setCollapsed(false);
  //   } else {
  //     setCollapsed(true);
  //   }
  //   console.log(collapsed);
  // };
  return (
    <div
     
    >
      {/* <div style={{ width: "100%" }}>
      <Button
          onClick={toggleCollapsed}
          style={{
            border: "none",
            marginBottom: 10,
            width: "30%",
            fontSize: 30,
            paddingTop: 0,
          }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined style={{ paddingTop: 0 }} />
          ) : (
            <MenuFoldOutlined style={{ paddingTop: 0 }} />
          )}
        </Button>
        
      
      </div> */}
    
      {/* <div className="logo">
        <a href="#">
          <img src={SCRIPTINDIABLUELOGO} height="50" alt="SCRIPT INDIA" />
        </a>
      </div> */}
      <Menu
        defaultSelectedKeys={["2"]}
        defaultOpenKeys={["sub2"]}
        mode="inline"
        theme="light"
  
        items={items}
        onClick={(info) => {
          navigate("/" + info.key);
          //console.log(info.key);
        }}
      />
    </div>
  );
};
export default SideMenu;
