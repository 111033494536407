import React from "react";

import SideBar from "../../Layout/Sidebar/SideBar";
import HeaderTop from "../../Layout/HeaderTop";
import { useRef, useState, useEffect, useContext } from "react";
//import AuthContext from '../context/AuthProvider';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import UnitMasterDDL from "../DropdownList/UnitMasterDDL";
import { width } from "@mui/system";
import { blue } from "@mui/material/colors";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SideMenu from "../../Layout/SideMenu";
import { Layout, Space } from "antd";
import FooterBottom from "../../Layout/FooterBottom";
import moment from "moment";
import dayjs from "dayjs";

export const EmployeeMaster = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffff",
  };

  const contentStyle: React.CSSProperties = {
    float: "left",
    // textAlign: "center",
    // minHeight: 120,
    // lineHeight: "120px",
    // color: "#fff",
    // backgroundColor: "#108ee9",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "#ffff",
  };

  const footerStyle: React.CSSProperties = {
    backgroundColor: "#eee",
  };

  const GetEmployeeCardNoURL = "/GetEmployeeCardNo";
  const INSERTEmployee_URL = "/InsertEmployeeDetails";
  const UPDATEEmployee_URL = "/UpdateEmployeeDetails";
  const EmployeeNDETAIL_URL = "/GetEmployeeFromEmployeeId";
  const UnitListURL = "/GetUnitListFromCompanyId";
  const DepartmentListURL = "/GetDepartmentListFromCompanyId";
  const DesignationListURL = "/GetDesignationListFromCompanyId";
  // const ShiftGroupListURL = "/GetShiftGroupListFromCompanyId";
  const ShiftListURL = "/GetShiftListFromCompanyId";
  const UserListURL = "/GetUnitListFromCompanyId";

  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const UnitMasterDDLRef = useRef();
  const [UnitItems, setUnitItems] = React.useState([]);
  const [DepartmentItems, setDepartmentItems] = React.useState([]);
  const [DesignationItems, setDesignationItems] = React.useState([]);
  // const [ShiftGroupItems, setShiftGroupItems] = React.useState([]);

  const [ShiftItems, setShiftItems] = React.useState([]);
  const [EmployeeId, setEmployeeId] = useState(0);
  const [UnitId, setUnitId] = useState(0);
  const [ShiftId, setShiftId] = useState(0);
  const [DepartmentId, setDepartmentId] = useState(0);
  const [DesignationId, setDesignationId] = useState(0);
  // const [ShiftgroupId, setShiftgroupId] = useState(0);

  const [UnitName, setUnitName] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [EmployeeCardNo, setEmployeeCardNo] = useState("");
  const [EmployeeDeviceCode, setEmployeeDeviceCode] = useState("");
  const [EmployeeGuardian, setEmployeeGuardian] = useState("");

  const [ESICNo, setESICNo] = useState("");
  const [Gender, setGender] = useState("Male");
  const [WeekOffDay, setWeekOffDay] = useState("6");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Nationality, setNationality] = useState("");
  const [PANNo, setPANNo] = useState("");
  const [Password, setPassword] = useState("");
  const [PermenentAddress, setPermenentAddress] = useState("");
  const [CurrentAddress, setCurrentAddress] = useState("");
  const [PFAccountNo, setPFAccountNo] = useState("");
  const [AdharCardNo, setAdharCardNo] = useState("");
  const [BankAccountNo, setBankAccountNo] = useState("");
  const [BankName, setBankName] = useState("");
  const [BrankBranch, setBrankBranch] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [Email, setEmail] = useState("");

  let newDateTime = new Date().toLocaleTimeString();
  const [PFJoinDate, setPFJoinDate] = useState(new Date());
  const [DateOfBirth, setDateOfBirth] = useState(new Date());
  const [DateOfJoining, setDateOfJoining] = useState(new Date());
  const [DateOfResign, setDateOfResign] = useState(new Date());

  const [PhoneNoHome, setPhoneNoHome] = useState("");
  const [Relation, setRemarks] = useState("");
  const [ResignReason, setResignReason] = useState("");
  // const [ShiftGroupId, setShiftGroupId] = useState("");
  // const [ShiftGroupName, setShiftGroupName] = useState("");
  const [BloodGroup, setBloodGroup] = useState("");
  const [Religion, setReligion] = useState("");
  const [Qualification, setQualification] = useState("");

  const [UAN, setUAN] = useState("");

  const [IsActive, setIsActive] = useState(false);
  const [CompanyId, setCompanyId] = useState("");
  const [EmployeeType, setEmployeeType] = useState(0);
  const [MSG, setErrMsg] = useState("");
  const [sucecess, setSucecess] = useState(false);
  const [btnLabel, setbtnLabel] = useState(false);

  const { state } = useLocation(0);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
      setIsActive(true);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsActive(false);
    }

    console.log(IsActive);
  };

  const setIsActiveValue = (value) => {
    setIsActive((pre) => (pre = value));
  };

  useEffect(() => {
    const doFetchEmployeeCardNo = async () =>{
      const responsecard = await axios.get(
        GetEmployeeCardNoURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setEmployeeCardNo(responsecard.data.employeeCardNo);
    };
    const doFetchUnit = async () => {
      const responseunit = await axios.get(
        UnitListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setUnitItems(
        responseunit.data.map(({ unitId, unitName }) => ({
          label: unitName,
          value: unitId,
        }))
      );
      const responseShift = await axios.get(
        ShiftListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setShiftItems(
        responseShift.data.map(({ shiftId, shiftName }) => ({
          label: shiftName,
          value: shiftId,
        }))
      );
      const responsedepartment = await axios.get(
        DepartmentListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setDepartmentItems(
        responsedepartment.data.map(({ departmentId, departmentName }) => ({
          label: departmentName,
          value: departmentId,
        }))
      );
      const responsedesignation = await axios.get(
        DesignationListURL + "?CompanyId=" + localStorage.getItem("companyId")
      );
      setDesignationItems(
        responsedesignation.data.map(({ designationId, designationName }) => ({
          label: designationName,
          value: designationId,
        }))
      );
      // const responseshiftgroup = await axios.get(
      //   ShiftGroupListURL + "?CompanyId=" + localStorage.getItem("companyId")
      // );
      // setShiftGroupItems(
      //   responseshiftgroup.data.map(({ shiftGroupId, shiftGroupName }) => ({
      //     label: shiftGroupName,
      //     value: shiftGroupId,
      //   }))
      // );
    };
    doFetchUnit();
    //doFetchEmployeeCardNo();
    setEmployeeId(state.id);
    if (state.id == 0) {
      //alert('New');
      doFetchEmployeeCardNo();
      setEmployeeName("");
      setIsActive(false);
      setbtnLabel("Save");
    } else {
      // alert(state.id);
      const FetchEmployeeDetails = async () => {
        const response = await axios.get(
          EmployeeNDETAIL_URL + "?EmployeeId=" + state.id
        );

        setEmployeeName(response.data.employeeName);
        setEmployeeId(response.data.employeeId);
        setEmployeeCardNo(response.data.employeeCardNo);
        setEmployeeDeviceCode(response.data.employeeDeviceCode);
        setAdharCardNo(response.data.adharCardNo);
        setBankAccountNo(response.data.bankAccountNo);
        setBankName(response.data.bankName);
        setCurrentAddress(response.data.currentAddress);
        setUnitName(response.data.unitName);
        setUnitId(response.data.unitId);
        setEmployeeId(response.data.employeeId);

        setDateOfBirth(response.data.dateOfBirth);
        setDepartmentId(response.data.departmentId);
        setDateOfJoining(response.data.dateOfJoining);
        setPFJoinDate(response.data.pFJoinDate);

        //UnitChange();

        setDesignationId(response.data.designationId);
        // setShiftGroupId(response.data.shiftGroupId);
        setShiftId(response.data.shiftId);
        setEmail(response.data.email);
        setEmployeeGuardian(response.data.employeeGuardian);
        setESICNo(response.data.eSICNo);
        setGender(response.data.gender);
        setIFSC(response.data.iFSC);
        setMobileNo(response.data.mobileNo);
        setNationality(response.data.nationality);
        setPANNo(response.data.panNo);
        setPassword(response.data.password);
        setPermenentAddress(response.data.permenentAddress);
        setPFAccountNo(response.data.pfAccountNo);
        setPhoneNoHome(response.data.phoneNoHome);
        setQualification(response.data.qualification);
        setEmployeeType(response.data.employeeType);
        // console.log(UnitId);
        console.log(response.data.status);
        if (response.data.status == "true") {
          console.log("set True");
          setIsActiveValue(true);
          console.log(IsActive);
        } else {
          console.log("set False");

          // setIsActive(false);
          setIsActiveValue(false);
          console.log(IsActive);
        }
      };
      FetchEmployeeDetails();
      setbtnLabel("Update");
    }

    userRef.current.focus();
  }, []);

  const UnitChange = async (e) => {
    setUnitId(e.target.value);
    const responsedepartment = await axios.get(
      DepartmentListURL +
      "?CompanyId=" +
      localStorage.getItem("companyId") +
      "&UnitId=" +
      e.target.value
    );
    setDepartmentItems(
      responsedepartment.data.map(({ departmentId, departmentName }) => ({
        label: departmentName,
        value: departmentId,
      }))
    );
    const responsedesignation = await axios.get(
      DesignationListURL +
      "?CompanyId=" +
      localStorage.getItem("companyId") +
      "&UnitId=" +
      e.target.value
    );
    setDesignationItems(
      responsedesignation.data.map(({ designationId, designationName }) => ({
        label: designationName,
        value: designationId,
      }))
    );
    // const responseshiftgroup = await axios.get(
    //   ShiftGroupListURL +
    //     "?CompanyId=" +
    //     localStorage.getItem("companyId") +
    //     "&UnitId=" +
    //     e.target.value
    // );
    // setShiftGroupItems(
    //   responseshiftgroup.data.map(({ shiftGroupId, shiftGroupName }) => ({
    //     label: shiftGroupName,
    //     value: shiftGroupId,
    //   }))
    // );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //          alert(userId);

      //    console.log(userId);
      if (EmployeeId == 0) {
        const response = await axios.post(
          INSERTEmployee_URL,
          JSON.stringify({
            EmployeeCardNo: EmployeeCardNo,
            EmployeeDeviceCode: EmployeeDeviceCode,
            EmployeeName: EmployeeName,
            EmployeeGuardian: EmployeeGuardian,
            CurrentAddress: CurrentAddress,
            PermenentAddress: PermenentAddress,
            AdharCardNo: AdharCardNo,
            MobileNo: MobileNo,
            PhoneNoHome: PhoneNoHome,
            UnitId: UnitId,
            Email: Email,
            BloodGroup: BloodGroup,
            DateOfBirth: DateOfBirth,
            Gender: Gender,
            Nationality: Nationality,
            Religion: Religion,
            MaritalStatus: MaritalStatus,
            Qualification: Qualification,
            DateOfJoining: DateOfJoining,
            UAN: UAN,
            PFAccountNo: PFAccountNo,
            PFJoinDate: PFJoinDate,
            ESICNo: ESICNo,
            PANNo: PANNo,
            BankName: BankName,
            BrankBranch: BrankBranch,
            BankAccountNo: BankAccountNo,
            IFSC: IFSC,
            DateOfResign: DateOfResign,
            Password: Password,
            IsActive: IsActive,
            UnitId: UnitId,
            DepartmentId: DepartmentId,
            WeekOffDay: WeekOffDay,
            DesignationId: DesignationId,
            ShiftId: ShiftId,
            // AssignShift: ShiftId,
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
            CompanyId: localStorage.getItem("companyId"),
            EmployeeType: EmployeeType,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setEmployeeName("");
        setUnitId(0);
        setIsActive(false);

        //setCompanyId('');
        // UserList
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });

        // alert('Saved successfully.')
        navigate("/Employee");
      } else {
        const response = await axios.post(
          UPDATEEmployee_URL,
          JSON.stringify({
            EmployeeId: EmployeeId,
            EmployeeCardNo: EmployeeCardNo,
            EmployeeDeviceCode: EmployeeDeviceCode,
            EmployeeName: EmployeeName,
            EmployeeGuardian: EmployeeGuardian,
            CurrentAddress: CurrentAddress,
            PermenentAddress: PermenentAddress,
            AdharCardNo: AdharCardNo,
            MobileNo: MobileNo,
            PhoneNoHome: PhoneNoHome,
            UnitId: UnitId,
            Email: Email,
            BloodGroup: BloodGroup,
            DateOfBirth: DateOfBirth,
            Gender: Gender,
            Nationality: Nationality,
            Religion: Religion,
            MaritalStatus: MaritalStatus,
            Qualification: Qualification,
            DateOfJoining: DateOfJoining,
            UAN: UAN,
            PFAccountNo: PFAccountNo,
            PFJoinDate: PFJoinDate,
            ESICNo: ESICNo,
            PANNo: PANNo,
            BankName: BankName,
            BrankBranch: BrankBranch,
            BankAccountNo: BankAccountNo,
            IFSC: IFSC,
            DateOfResign: DateOfResign,
            Password: Password,
            //IsActive: IsActive,
            UnitId: UnitId,
            DepartmentId: DepartmentId,
            WeekOffDay: WeekOffDay,
            DesignationId: DesignationId,
            ShiftId: ShiftId,
            // AssignShift: ShiftId,
            EntryBy: localStorage.getItem("userId"),
            UpdateBy: localStorage.getItem("userId"),
            CompanyId: localStorage.getItem("companyId"),
            EmployeeType: EmployeeType,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          }
        );
        console.log(response.data);
        setErrMsg(response.data);
        setEmployeeName("");
        setEmployeeId(0);
        setIsActive(false);
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/Employee");
      }
    } catch (error) {
      console.log("error", error);
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="layoutContainer screenCenter">
        <div className="row">
          <form onSubmit={handleSubmit} style={{ position: "relative" }}>
            <div
              className="col-lg-12"
              style={{
                textAlign: "Left",
              }}
            >
              {" "}
              <h4 style={{ paddingBottom: "20px" }}>
                <b>Employee Registration</b>
              </h4>
            </div>
            <div
              className="row EmployeeContainer"
              style={{ border: "1px solid #ccc", padding: "20px" }}
            >

              <div className="col-sm-3">
                <label className="form-controll" htmlFor="EmployeeCardNo">
                  Card No
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text" 
                  className="form-controll"
                  id="EmployeeCardNo"
                  onChange={(e) => setEmployeeCardNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={EmployeeCardNo}
                  placeholder="Card No"
                  required 
                  //disabled 
                  //readonly
                ></input>
              </div>
              <div className="col-sm-3">
                <label
                  className="form-controll"
                  htmlFor="EmployeeDeviceCode"
                >
                  Device Code
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="EmployeeDeviceCode"
                  onChange={(e) => setEmployeeDeviceCode(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={EmployeeDeviceCode}
                  placeholder="Device Code"
                ></input>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="EmployeeName">
                  Employee Name
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="EmployeeName"
                  onChange={(e) => setEmployeeName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={EmployeeName}
                  placeholder="Employee Name"
                  required
                ></input>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="DateOfBirth">
                  Date of Birth
                </label>{" "}
                <br></br>
                {/* <input type="date" className="form-controll" id="DateOfBirth" onChange={(e) => setDateOfBirth(e.target.value)}
  ref={userRef} autoComplete="off" value={DateOfBirth}  required></input> */}
                {/* <DatePicker
                        selected={DateOfBirth}
                        name="DateOfBirth"
                        onChange={(date) => setDateOfBirth(date)}
                        dateFormat="dd-MMM-yyyy"
                      /> */}
                <DatePicker
                  className="form-controll"
                  format="DD-MMM-YYYY "
                  onChange={(date) => setDateOfBirth(date)}
                  value={dayjs(DateOfBirth)}
                />
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="MobileNo">
                  Mobile No
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="MobileNo"
                  onChange={(e) => setMobileNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={MobileNo}
                  placeholder="MobileNo"
                // required
                ></input>
              </div>
              <div className="col-sm-3">
                <label className="form-controll">Gender</label>
                {/* &nbsp; */}
                <select
                  className="form-controll ant-select"
                  value={Gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="CurrentAddress">
                  Current Address
                </label>{" "}
                <textarea
                  name="message"
                  rows="3"
                  cols="30"
                  type="Text"
                  className="form-controll ant-textarea"
                  id="CurrentAddress"
                  onChange={(e) => setCurrentAddress(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={CurrentAddress}
                  placeholder="Current Address"
                  required
                ></textarea>
              </div>
              <div className="col-sm-3">
                <label
                  className="form-controll"
                  htmlFor="PermenentAddress"
                >
                  Permenent Address
                </label>{" "}
                <textarea
                  rows="3"
                  cols="30"
                  type="Text"
                  className="form-controll ant-textarea"
                  id="PermenentAddress"
                  onChange={(e) => setPermenentAddress(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={PermenentAddress}
                  placeholder="PermenentAddress"
                // required
                ></textarea>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="Email">
                  Email
                </label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Email}
                  placeholder="Email"
                // required
                ></input>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="Nationality">
                  Nationality
                </label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="Nationality"
                  onChange={(e) => setNationality(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Nationality}
                  placeholder="Nationality"
                // required
                ></input>
              </div>
              <div className="col-sm-3">
                <label className="form-controll">Unit</label>
                {/* &nbsp; */}
                <select
                  onChange={(e) => setUnitId(e.target.value)}
                  className="dropdown-toggle ant-select"
                >
                  {UnitItems.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={item.value === UnitId}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll">Department</label>
                {/* &nbsp; */}
                <select
                  value={DepartmentId}
                  onChange={(e) => setDepartmentId(e.target.value)}
                  className="dropdown-toggle ant-select"
                >
                  {DepartmentItems.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={item.value === DepartmentId}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll">Designation</label>{" "}
                <select
                  value={DesignationId}
                  onChange={(e) => setDesignationId(e.target.value)}
                  className="dropdown-toggle ant-select"
                >
                  {DesignationItems.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={item.value === DesignationId}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {/*<div className="col-sm-3">
                      <label className="form-controll">Shift Group</label>{" "}
                      <select
                        value={ShiftGroupId}
                        onChange={(e) => setShiftGroupId(e.target.value)}
                        className="dropdown-toggle ant-select"
                      >
                        {ShiftGroupItems.map((item) => (
                          <option
                            key={item.value}
                            value={item.value}
                            selected={item.value === ShiftGroupId}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>*/}
              <div className="col-sm-3">
                <label className="form-controll">Shift</label>{" "}
                <select
                  value={ShiftId}
                  onChange={(e) => setShiftId(e.target.value)}
                  className="dropdown-toggle ant-select"
                >
                  {ShiftItems.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={item.value === ShiftId}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll">Date of Joining</label>{" "}
                {/* <input type="date" className="form-controll" id="DateOfJoining" onChange={(e) => setDateOfJoining(e.target.value)}
  ref={userRef} autoComplete="off" value={DateOfJoining}  required></input>
   */}
                {/* <DatePicker
                      selected={DateOfJoining}
                      name="DateOfJoining"
                      onChange={(date) => setDateOfJoining(date)}
                      dateFormat="dd-MMM-yyyy"
                    /> */}
                <DatePicker
                  format="DD-MMM-YYYY "
                  onChange={(date) => setDateOfJoining(date)}
                  value={dayjs(DateOfJoining)}
                />
              </div>
              <div className="col-sm-3">
                <label className="form-controll">WeekOffDay</label>
                <select
                  className="form-controll ant-select"
                  value={WeekOffDay}
                  onChange={(e) => setWeekOffDay(e.target.value)}
                >
                  <option value="6">Sunday</option>
                  <option value="0">Monday</option>
                  <option value="1">Tuesday</option>
                  <option value="2">Wednesday</option>
                  <option value="3">Thursday</option>
                  <option value="4">Friday</option>
                  <option value="5">Saturday</option>
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="Password">
                  Password
                </label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={Password}
                  placeholder="Password"
                  required
                ></input>
              </div>
              <div
                class="col-sm-12"
                style={{
                  "border-bottom": "1px solid #ccc",
                  margin: "20px 0px",
                }}
              ></div>
              <div class="col-sm-12">
                <h6 style={{ color: "blue" }}>Gov Info</h6>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">PF Account No</label>{" "}
                {/* &nbsp; */}
                <input
                  type="Text"
                  className="form-controll"
                  id="PFAccountNo"
                  onChange={(e) => setPFAccountNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={PFAccountNo}
                  placeholder="PFAccountNo"
                //  required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">PF Join Date</label>{" "}
                {/* &nbsp; */}
                <br></br>
                {/* <input type="date" className="form-controll" id="PFJoinDate" onChange={(e) => setPFJoinDate(e.target.value)}
  ref={userRef} autoComplete="off" value={PFJoinDate} placeholder="PFJoinDate" required></input>

    */}
                {/* <DatePicker
                      selected={PFJoinDate}
                      name="PFJoinDate"
                      onChange={(date) => setPFJoinDate(date)}
                      dateFormat="dd-MMM-yyyy"
                    /> */}
                <DatePicker
                  format="DD-MMM-YYYY "
                  onChange={(date) => setPFJoinDate(date)}
                  value={dayjs(PFJoinDate)}
                />
              </div>
              <div class="col-sm-3">
                <label className="form-controll">ESIC No</label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id=""
                  onChange={(e) => setESICNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={ESICNo}
                  placeholder="ESIC No"
                //required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">PAN No</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="PANNo"
                  onChange={(e) => setPANNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={PANNo}
                  placeholder="PANNo"
                // required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">UAN</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="UAN"
                  onChange={(e) => setUAN(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={UAN}
                  placeholder="UAN"
                // required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">AdharCard No</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="AdharCardNo"
                  onChange={(e) => setAdharCardNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={AdharCardNo}
                  placeholder="AdharCard No"
                // required
                ></input>
              </div>
              <div className="clearfix"></div>
              <div
                class="col-sm-12"
                style={{
                  "border-bottom": "1px solid #ccc",
                  margin: "20px 0px",
                }}
              ></div>
              <div class="col-sm-12">
                <h6 style={{ color: "blue" }}>Bank Info</h6>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">BankName</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="BankName"
                  onChange={(e) => setBankName(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={BankName}
                  placeholder="BankName"
                // required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">BankAccount No</label>{" "}
                <input
                  type="Text"
                  className="form-controll"
                  id="BankAccountNo"
                  onChange={(e) => setBankAccountNo(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={BankAccountNo}
                  placeholder="BankAccount No"
                //required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">IFSC</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="IFSC"
                  onChange={(e) => setIFSC(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={IFSC}
                  placeholder="IFSC"
                // required
                ></input>
              </div>
              <div class="col-sm-3">
                <label className="form-controll">Bank Branch</label>
                <input
                  type="Text"
                  className="form-controll"
                  id="BrankBranch"
                  onChange={(e) => setBrankBranch(e.target.value)}
                  ref={userRef}
                  autoComplete="off"
                  value={BrankBranch}
                  placeholder="Bank Branch"
                //required
                ></input>
              </div>
              <div className="col-lg-4">
                {/* <label className="form-controll" >Month</label> &nbsp; */}
                <select
                  value={EmployeeType}
                  onChange={(e) => setEmployeeType(e.target.value)}
                  className="ant-select"
                >
                  <option value="0">Select Employee Type</option>
                  <option value="1">Fixed Salary</option>
                  <option value="2">Non Fixed Salary</option>
                </select>
              </div>
              <div className="col-sm-3">
                <label className="form-controll" htmlFor="IsActive">
                  Is Active
                </label>{" "}
                <input
                  type="CheckBox"
                  id="IsActive"
                  checked={IsActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                ></input>
              </div>

              <div className="col-sm-3">
                <button
                  style={{ width: "100px" }}
                  className="btn btn-primary"
                >
                  {" "}
                  {btnLabel}
                </button>
                &nbsp;
                <button
                  style={{ width: "100px" }}
                  className="btn btn-danger"
                >
                  {" "}
                  Clear
                </button>
              </div>
              <div>
                <label>{MSG}</label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
